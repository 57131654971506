//////////////////////////////////////////////
// Smooth scrolling
const allLinks = document.querySelectorAll('a:link');

allLinks.forEach(function (link) {
  link.addEventListener('click', function (e) {
    const href = link.getAttribute('href');
    if (!href.startsWith('#')) return;
    e.preventDefault();

    // Scroll to top
    if (href === '#') window.scrollTo({ top: 0, behavior: 'smooth' });

    // Scroll to section on page
    if (href !== '#' && href.startsWith('#')) {
      const selectionEl = document.querySelector(href);
      selectionEl.scrollIntoView({ behavior: 'smooth' });
    }

    //IF we later have mobile navigation - implement code to also close the navigation on top of page.
  });
});

//////////////////////////////////////////////
// Sticky navigation

const sectionHeaderEl = document.querySelector('.header');

const obs = new IntersectionObserver(
  function (entries) {
    const ent = entries[0];
    if (!ent.isIntersecting) document.body.classList.add('sticky');
    if (ent.isIntersecting) document.body.classList.remove('sticky');
  },
  {
    root: null,
    threshold: 0,
    rootMargin: '-80px',
  }
);
